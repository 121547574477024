<template>
    <div id="misc">
      <img
        class="misc-mask"
        height="226"
        :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
      />
  
      <v-img
        class="misc-tree"
        src="@/assets/images/misc/tree-4.png"
      ></v-img>
  
      <div class="page-title text-center px-4">
        <div class="misc-center-content text-center mb-4">
        <!-- 👉 Title and subtitle -->
        <h1 class="text-h1">
          403
        </h1>
        <h5 class="text-h5 mb-3">
          You are not authorized! 🔐
        </h5>
        <p>You don't have permission to access this page. Go Home!</p>
      </div>
  
        <div class="misc-character d-flex justify-center">
          <v-img
            max-width="700"
            src="@/assets/images/3d-characters/not-authorized.png"
          ></v-img>
        </div>
  
        <v-btn
          color="primary"
          to="/dashboards/crm"
          class="mb-4"
        >
          Back to home
        </v-btn>
      </div>
    </div>
  </template>
  
  <script>
  import { mdiAlert } from '@mdi/js'

  export default {
    setup() {
      return {
        icons: {
          mdiAlert,
        },
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/preset/preset/misc.scss';
  </style>
  